import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import Layout from "../components/layout";
import Meta from "../components/Meta";
import Section from "../components/section";
import Slider from "../components/Slider";
import { graphql } from "gatsby";

export default function homeTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark;
  const { section, slider } = frontmatter;

  return (
    <Layout>
      <Meta title={frontmatter.title} metaTitle={frontmatter.metaTitle} metaDescription={frontmatter.metaDescription} />
      <Container className="text-center my-2 py-2">
        <Row>
          <Col ><h1>{frontmatter.title}</h1></Col>
        </Row>
      </Container>
      <Slider slider={slider} />
      <Container className="text-center mt-1">
        <Row>
          <Col ><h2>{frontmatter.bannerTitle}</h2></Col>
        </Row>
      </Container>
      <Container className="mt-2">
        <Section sections={section} />
      </Container>
      {frontmatter.date ? <Container><Row>
        <Col className="text-right"><time datetime={frontmatter.date}>Aggiornato il {frontmatter.date}</time></Col></Row>
      </Container> : ''}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        bannerTitle
        title
        content
        altImage
        image
        metaTitle
        metaDescription
        section {
          id
          title
          body
          image
          altImage
          link
          linkLabel
        }
        slider {
          id
          image
          title
          altImage
          link
        }
      }
    }
}
`